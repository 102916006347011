import React from "react";
import { Link } from "react-router-dom";
// enums
import { EResourceType } from "cms/enums/EResourceType";
import ESizeType from "cms/enums/ESizeType";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
// utilities
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
import RMDRImage from "components/RMDRImage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import { EContentType } from "cms/enums/EContentType";


type TMiddleBar = {

    siteSettingPayload: IClientSiteSettingDetail
    
}

const MiddleBar = ( props:TMiddleBar ) => {
    console.log("MiddleBar.rendered", props)
    
    const ourLocationString = LanguageVersionSelector<ILanguageVersionList|undefined>({ languageVersions: props.siteSettingPayload?.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "ourLocationString" }], type: "find"});
    const addressFormatted = LanguageVersionSelector<ILanguageVersionList|undefined>({ languageVersions: props.siteSettingPayload?.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "addressFormatted" }], type: "find"});

    const hr24SupportString = LanguageVersionSelector<ILanguageVersionList|undefined>({ languageVersions: props.siteSettingPayload?.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "24HrSupportString" }], type: "find"});
    const email = LanguageVersionSelector<ILanguageVersionList|undefined>({ languageVersions: props.siteSettingPayload?.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "email" }], type: "find"});

    const workingHoursString = LanguageVersionSelector<ILanguageVersionList|undefined>({ languageVersions: props.siteSettingPayload?.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "workingHoursString" }], type: "find"});
    const workingHours = LanguageVersionSelector<ILanguageVersionList|undefined>({ languageVersions: props.siteSettingPayload?.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "workingHours" }], type: "find"});

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    return (
        <div className="ttm-bgcolor-white">
            <div className="container-xl">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex flex-rowalign-items-center">

                            <div className="site-branding me-auto align-self-center">
                                <Link className="home-link" to={'/'} title="ARMADOR" rel="home">
                                    <RMDRImage
                                        id="logo-img"
                                        resourceType={EResourceType.LOGO_DEFAULT} 
                                        resources={props.siteSettingPayload?.resources} 
                                        sizeType={ESizeType.X06}
                                        alt={languageVersion?.name} 
                                        title={languageVersion?.name} 
                                        className="img-center tw-max-w-64 tw-h-auto"
                                    />
                                </Link>
                            </div>
                            
                            <div className="widget_info d-flex flex-row justify-content-end">
                                <div className="widget_icon tw-mt-3"><i className="flaticon-call" /></div>
                                <div className="widget_content">
                                    <h5 className="widget_title">{ workingHoursString?.value }</h5>
                                    <p className="widget_desc">{ workingHours?.value }</p>
                                </div>
                            </div>
                            
                            <div className="widget_info d-flex flex-row justify-content-end">
                                <div className="widget_icon tw-mt-3"><i className="flaticon-clock" /></div>
                                <div className="widget_content">
                                    <h5 className="widget_title">{ hr24SupportString?.value }</h5>
                                    <p className="widget_desc">{email?.value}</p>
                                </div>
                            </div>
                            
                            <div className="widget_info d-flex flex-row justify-content-end">
                                <div className="widget_icon tw-mt-3"><i className="flaticon-global-1" /></div>
                                <div className="widget_content">
                                    <h5 className="widget_title">{ ourLocationString?.value }</h5>
                                    <p className="widget_desc ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(addressFormatted?.value) }} />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

const Component = React.memo(MiddleBar, ( prevProps:TMiddleBar, nextProps:TMiddleBar ) => {
    console.log("MiddleBar.memo", { prevProps, nextProps });
    return !( 
        prevProps.siteSettingPayload !== nextProps.siteSettingPayload
    )
});

export default Component;
export type {
    TMiddleBar
};
