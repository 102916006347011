import { CSSProperties, JSXElementConstructor, ReactNode } from "react";
import {  isEmpty,isNumber, reject } from "lodash";
import { Link } from "react-router-dom";
// enums
import ERedirectType from 'cms/enums/ERedirectType';

type Variant = 'a' | 'div';

type TRMDRLink = {

    title?: string|null;
    text?:string|null;

    hasNavigationDestination?: boolean|null;
    isShowAlways?: boolean|null;

    navigationURL?: string|null;

    redirectType?: ERedirectType;
    redirectUrl?: string|null;

    className?: string;
    style?: CSSProperties;

    children?: ReactNode;

    onClick?: (event:any) => void;

    variant?: Variant;
}

function RMDRLink ( props:Readonly<TRMDRLink> ) {

    const _target = ((props.redirectType === ERedirectType.STAY_CURRENT_PAGE || !isNumber(props.redirectType)) && "_self") || (props.redirectType === ERedirectType.REDIRECT_NEW_TAB && "_blank") || "";
    
    if ( props.hasNavigationDestination && !isEmpty(props.redirectUrl) && props.redirectUrl ) {

        return <a 
            className={props.className}
            style={props.style}
            href={props.redirectUrl}
            target={_target}
            title={props.title||""}
            onClick={props.onClick}
        >
            { reject( [props.text, props.children, props.redirectUrl], isEmpty )?.[0] }
        </a>
        
    }

    if ( props.hasNavigationDestination && !isEmpty(props.navigationURL) && props.navigationURL ) {
        
        return <Link 
            className={props.className}
            style={props.style}
            to={props.navigationURL}
            target={_target}
            title={props.title||""}
            onClick={props.onClick}
        >
            { reject( [props.text, props.children, props.navigationURL], isEmpty )?.[0] }
        </Link>

    }

    if ( !isEmpty(props.onClick) ) {

        const componentsMap: {
            [P in Variant]: React.ComponentType<any> | string;
        } = {
            a: 'a',
            div: 'div'
        };
    
        const Component: JSXElementConstructor<any> | React.ReactElement<any> | React.ComponentType<any> | string = componentsMap![props.variant!];

        return <Component
            className={props.className}
            style={props.style}
            onClick={props.onClick}
            title={props.title||""}
        >
            { reject( [props.text, props.children], isEmpty )?.[0] }
        </Component>
        
    }

    if ( props.isShowAlways ) {

        const componentsMap: {
            [P in Variant]: React.ComponentType<any> | string;
        } = {
            a: 'a',
            div: 'div'
        };
    
        const Component: JSXElementConstructor<any> | React.ReactElement<any> | React.ComponentType<any> | string = componentsMap![props.variant!];

        return <Component 
            className={props.className}
            onClick={props.onClick}
            title={props.title||""}
        >
            { reject( [props.text, props.redirectUrl, props.children], isEmpty )?.[0] }
        </Component>;

    }
        
    return null;
    
}

RMDRLink.defaultProps = {

    hasNavigationDestination : false,
    isShowAlways: false,
    className : "",
    variant: "a"

};

export default RMDRLink;
export type {
    TRMDRLink
};
