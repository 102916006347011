import { isEmpty, reject } from 'lodash';
import CoreDefault from 'core/Defaults';
// utilities
import SanitizeHTMLString from 'cms/utilities/SanitizeHTMLString';
import ApplicationDocumentSelector, { TApplicationDocumentSelector } from 'cms/utilities/ApplicationDocumentSelector';
import useProgressiveImage from 'core/hooks/useProgressiveImage';
import IApplicationDocument from 'cms/interfaces/IApplicationDocument';


type TContentHeaderRow = {

    title?: string|null;
    description?: string|null;
    imagePath?: string|null;
    
} & TApplicationDocumentSelector;


function ContentHeaderRow ( props:TContentHeaderRow ) {

    const applicationDocument = ApplicationDocumentSelector<IApplicationDocument|null>({...props, type: "find"});

    const image = reject([
        props.imagePath ? `${CoreDefault.cdn}/${props.imagePath.replace("\\", "/")}` : null,
        applicationDocument?.filePath ? `${CoreDefault.cdn}/${applicationDocument.filePath.replace("\\", "/")}` : null
    ], isEmpty)?.[0];

    const loaded = useProgressiveImage({ src:image ? `${image}` : null });

    return (
        <div 
            className="ttm-page-title-row" 
            style={loaded?{backgroundImage: `url('${loaded}')`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}:undefined}
        >
            <div className="container-xl">

                <div className="row">
                    <div className="col-md-12">
                        <div className="title-box text-start">
                            <div className="page-title-heading">
                                <h1 className="title">{props.title}</h1>
                            </div>
                            <div className="breadcrumb-wrapper">
                                <span className="ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(props.description)}} />
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )

}

export default ContentHeaderRow;
export type {
    TContentHeaderRow
};
