import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { connect, ConnectedProps } from "react-redux"
import { useOutletContext } from "react-router-dom";
import { Dispatch } from "@reduxjs/toolkit";
// components
import Layouts from "components/Layouts";
import SEO from "components/SEO";
import ErrorBoundary from "./ErrorBoundary";
import ContentHeaderRowLoader from "components/ContentHeaderRowLoader";
import ContentRendererLoader from "components/ContentRendererLoader";
// actions
import CoreAction from "core/actions/common/CoreAction";
import ClientContentsServiceAction from "cms/actions/screens/Content/ClientContentsServiceAction";
import ContentMetricsServiceAction from "cms/actions/screens/Content/ContentMetricsServiceAction";
//interfaces
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientContentDetail from "cms/interfaces/IClientContentDetail";
// types
import TValidations from "cms/types/TValidations";
import { EContentMetricsType } from "core/enums/EContentMetricsType";


function mapStateToProps ( state: any ) {
    console.log("Content.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;

    const contentDetailPayload:IClientContentDetail|null    = state.ContentStore.ClientContentsStore.details?.payload;
    const contentDetailError:TValidations[]|null            = state.ContentStore.ClientContentsStore.details?.error;
    const contentDetailIsLoading:boolean                    = state.ContentStore.ClientContentsStore.details?.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,

        contentDetailPayload,
        contentDetailError,
        contentDetailIsLoading,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    const coreAction = new CoreAction(dispatch);
    const clientContentsServiceAction = new ClientContentsServiceAction(dispatch);
    const contentMetricsServiceAction = new ContentMetricsServiceAction(dispatch);

    return {
        // list
        // post
        _viewEvent: ( contentId:string, webApplicationLanguageCode:string ) => {
            contentMetricsServiceAction.post({ 
                servicePayload: { 
                    body: {
                        contentId: contentId,
                        metricType: EContentMetricsType.VIEV
                    }, 
                    language: webApplicationLanguageCode 
                } 
            });
        },

        // details
        _detailsContent: ( contentId:string, webApplicationLanguageCode:string ) => {
            clientContentsServiceAction.details({ servicePayload: { key: contentId, language: webApplicationLanguageCode }});
        },

        // others
        _showLoader: () => { 
            coreAction.showLoader() 
        },

        _hideLoader: () => { 
            coreAction.hideLoader() 
        },

        _clean: () => {
            clientContentsServiceAction.detailsClean();
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TContent<T> = {

} & T;


/* component */
function Loader() {

    return (
        <>
            <ContentHeaderRowLoader />
            <ContentRendererLoader />
        </>
    );

}

function Content ( props:TContent<PropsFromRedux> ) {
    console.log("Content.rendered: ", props);

    const { contentName, contentId, location } = useOutletContext<any>();
    const { webApplicationLanguageCode, contentDetailPayload, contentDetailError, contentDetailIsLoading, siteSettingDetailPayload, _clean, _detailsContent, _viewEvent, _showLoader } = props;

    useEffect (
        () => {
            _showLoader();

            if ( !isEmpty(webApplicationLanguageCode) && webApplicationLanguageCode ) {    
                _clean();
                
                let _contentId:string|null|undefined = null;

                // co-founders
                if ( contentId === "burak-ozel" || contentId === "melih-sevsay" || contentId === "hasan-ali-ozturk" ) {
                    _contentId = siteSettingDetailPayload.languageVersions.find((item) => item.name === contentId)?.value;
                }

                setTimeout(() => { 
                    _detailsContent(_contentId||contentId, webApplicationLanguageCode); 
                    _viewEvent(contentId, props.webApplicationLanguageCode);

                }, 100)
            }
        }, [ contentId, webApplicationLanguageCode ]
    )

    if ( contentDetailPayload && !isEmpty(contentDetailPayload) && isEmpty(contentDetailError) ) {

        const layoutName = contentDetailPayload?.layouts?.[0]?.layoutName;
        let Layout:any = Layouts?.[layoutName];

        if ( Layout ) {

            return (
                <div className="site-main">
                    <SEO contentPayload={contentDetailPayload} siteSettingPayload={siteSettingDetailPayload} type="content-page" />
                    <Layout contentDetailPayload={contentDetailPayload} />
                </div>
            );

        }

    }
    else if ( isEmpty(contentDetailPayload) && !isEmpty(contentDetailError) ) {

        return <ErrorBoundary />
    
    }

    return null;

}

const Component = React.memo(Content, ( prevProps:TContent<PropsFromRedux>, nextProps:TContent<PropsFromRedux> ) => {
    return !(
        prevProps.contentDetailIsLoading !== nextProps.contentDetailIsLoading 
        || prevProps.webApplicationLanguageCode !== nextProps.webApplicationLanguageCode
    );
});

export default connector(Component);
