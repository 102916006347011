import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

type TUseProgressiveImage = {

    src:string|null;

}

const useProgressiveImage = (props:TUseProgressiveImage) => {  
    const [sourceLoaded, setSourceLoaded] = useState<string|null>(null);
  
    useEffect(
        () => {
            if ( props.src && !isEmpty(props.src) ) {
                const img = new Image();
                img.src = props.src;
                img.onload = () => setSourceLoaded(props.src);
            }
        }, [props.src]
    )
  
    return sourceLoaded 
}

export default useProgressiveImage;
