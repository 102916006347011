import { useEffect, useState, CSSProperties } from "react";
import { isEmpty } from "lodash";
import CoreDefaults from 'core/Defaults';
// utilities
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
import ApplicationDocumentSelector from "cms/utilities/ApplicationDocumentSelector";
// enums
import { EResourceType } from 'cms/enums/EResourceType';
import ESizeType from "cms/enums/ESizeType";
import EMimeType from "cms/enums/EMimeType";
// interfaces
import IResource from 'cms/interfaces/IResource';
import IApplicationDocument from "cms/interfaces/IApplicationDocument";
// image
import defaultImage from "assets/img/default-image.webp";


type TRMDRImageSrcset = {

    id?:string;
    src?: string;

    resources?: IResource[];
    
    resourceType?: EResourceType;
    sizeType?: ESizeType;

    alt?: string|null;
    title?: string|null;

    style?: CSSProperties;
    className?: string; 

    width?: number|string;
    height?: number|string;

    disableLazyLoading?:boolean;

    screenSizes:number[];

}

function RMDRImageSrcset ( props:Readonly<TRMDRImageSrcset> ) {

    const applicationDocument= ApplicationDocumentSelector<IApplicationDocument|null>({ 
        resources: props.resources,
        resourceType: props.resourceType,
        sizeType: props.sizeType,
        type: "find"
    });

    function replaceImage (error:any) {
    
        error.target.path = defaultImage;
    
    }

    if ( applicationDocument && !isEmpty(applicationDocument) ) {
        // const _filePath = applicationDocument?.filePath.replace("/x",`${props.screenSizes[0]}/x`); 
        // const _src = `${CoreDefaults.cdn}/${_filePath}`;
        
        const imageUrls:string[] = props.screenSizes.map((item) => { const _filePath = applicationDocument?.filePath.replace("/x",`/${item}/x`); return `${CoreDefaults.cdn}/${_filePath} ${item}w`});

        const sizes:string[] =  props.screenSizes.map((item) => `(max-width: ${item}px) ${item}px`);

        return <img 
            id={props.id} 
            srcSet={imageUrls.join(", ")}
            sizes={`${sizes.join(", ")}, ${props.screenSizes[0]}px`}
            src={defaultImage}
            alt={props.alt||""} 
            style={props.style} 
            className={props.className} 
            width={props.width||applicationDocument.width||"100%"} 
            height={props.height||applicationDocument.height||"100%"} 
            onError={replaceImage} 
            loading={props.disableLazyLoading?"eager":"lazy"} 
            title={props.title||""}
        />
    }

    return <img id={props.id} src={defaultImage} alt={"default"} style={props.style} className={props.className} width={props.width||"100%"} height={props.height||"100%"} loading={props.disableLazyLoading?"eager":"lazy"} title={"default"} />

}

export default RMDRImageSrcset;
export type {
    TRMDRImageSrcset
};
