import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import Lodash, { isEmpty } from "lodash";
import SlickSlider from "react-slick";
// components
import SliderLayouts from "./SliderLayouts";
// actions
import CoreAction from "core/actions/common/CoreAction";
import ClientSilderItemServiceAction from "cms/actions/components/Slider/ClientSilderItemServiceAction";
// interfaces
import IOdataQueryOptions from 'core/interfaces/IOdataQueryOptions';
// enums
import ELayoutType from "cms/enums/ELayoutType";
// 
import { getLocalDateTime } from "core/utilities/DateTimeHelper";
import IClientSliderItemList from "cms/interfaces/IClientSliderItemList";
import TValidations from "cms/types/TValidations";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import { Dispatch } from "@reduxjs/toolkit";
import { EContentStatus } from "cms/enums/EContentStatus";
import ApplicationDocumentSelector from "cms/utilities/ApplicationDocumentSelector";
import { EResourceType } from "cms/enums/EResourceType";
import CoreDefault from "core/Defaults";
import { Helmet } from "react-helmet";
import IApplicationDocument from "cms/interfaces/IApplicationDocument";


function mapStateToProps ( state: any ) {
    console.log("Slider.mapStateToProps: ", state);
    
    const siteSettingsDetailsPayload:IClientSiteSettingDetail   = state.SiteSettingStore.details?.payload;

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const sliderItemListPayload:IClientSliderItemList[]|null    = state.SliderStore.ClientSilderItemStore.list?.payload;
    const sliderItemListError:TValidations[]|null               = state.SliderStore.ClientSilderItemStore.list?.error;
    const sliderItemListIsLoading:boolean                       = state.SliderStore.ClientSilderItemStore.list?.isLoading;

    return {

        siteSettingsDetailsPayload,

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        sliderItemListPayload,
        sliderItemListError,
        sliderItemListIsLoading

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {

    const coreAction = new CoreAction(dispatch);
    const clientSilderItemServiceAction = new ClientSilderItemServiceAction(dispatch);

    return {
        // list
        _listSliderItems: ( categoryId: string, webApplicationLanguageCode:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`status eq ${EContentStatus.ACTIVE}`, "and", `(publishDate le ${getLocalDateTime()} or publishDate eq null)`, "and", `(expireDate ge ${getLocalDateTime(true)} or expireDate eq null)`],
                orderBy: ["order asc"],
                top: 1000
            };

            clientSilderItemServiceAction.list({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
        },
        
        // other
        _hideLoader: () => { 
            coreAction.hideLoader(); 
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TSlider<T> = {

    layoutName?: string;

} & T;

/* component */
function Loader () {

    return (
        <div className="container-fluid">
            <div id="ARMADOR_Banner_01" className="row">
                
                <div className="tw-animate-pulse tw-h-[37.5rem] tw-w-full tw-bg-gray-100 tw-pt-[120px] ">
                    <div className="container-xl tw-flex tw-gap-20">
                        <div className="tw-space-y-2.5 tw-flex-1 tw-pt-10">
                            <div className="tw-flex tw-items-center tw-w-full">
                                <div className="tw-h-2.5 tw-bg-gray-200 tw-rounded-full dark:tw-bg-gray-700 tw-w-4/12"></div>
                                <div className="tw-h-2.5 tw-ms-2 tw-bg-gray-300 tw-rounded-full dark:tw-bg-gray-600 tw-w-3/12"></div>
                                <div className="tw-h-2.5 tw-ms-2 tw-bg-gray-300 tw-rounded-full dark:tw-bg-gray-600 tw-w-full"></div>
                            </div>
                            <div className="tw-flex tw-items-center tw-w-full">
                                <div className="tw-h-2.5 tw-bg-gray-200 tw-rounded-full dark:tw-bg-gray-700 tw-w-5/12"></div>
                                        <div className="tw-h-2.5 tw-ms-2 tw-bg-gray-300 tw-rounded-full dark:tw-bg-gray-600 tw-w-5/12"></div>
                                <div className="tw-h-2.5 tw-ms-2 tw-bg-gray-300 tw-rounded-full dark:tw-bg-gray-600 tw-w-2/12"></div>
                            </div>
                            <div className="tw-flex tw-items-center tw-w-full">
                                <div className="tw-h-2.5 tw-bg-gray-300 tw-rounded-full dark:tw-bg-gray-600 tw-w-6/12"></div>
                                <div className="tw-h-2.5 tw-ms-2 tw-bg-gray-200 tw-rounded-full dark:tw-bg-gray-700 tw-w-2/12"></div>
                                <div className="tw-h-2.5 tw-ms-2 tw-bg-gray-300 tw-rounded-full dark:tw-bg-gray-600 tw-w-4/12"></div>
                            </div>
                            <div className="tw-flex tw-items-center tw-w-full">
                                <div className="tw-h-2.5 tw-bg-gray-200 tw-rounded-full dark:tw-bg-gray-700 tw-w-5/12"></div>
                                <div className="tw-h-2.5 tw-ms-2 tw-bg-gray-300 tw-rounded-full dark:tw-bg-gray-600 tw-w-6/12"></div>
                                <div className="tw-h-2.5 tw-ms-2 tw-bg-gray-300 tw-rounded-full dark:tw-bg-gray-600 tw-w-1/12"></div>
                            </div>
                            <div className="tw-flex tw-items-center tw-w-full">
                                <div className="tw-h-2.5 tw-bg-gray-300 tw-rounded-full dark:tw-bg-gray-600 tw-w-2/12"></div>
                                <div className="tw-h-2.5 tw-ms-2 tw-bg-gray-300 tw-rounded-full dark:tw-bg-gray-600 tw-w-1/12"></div>
                                <div className="tw-h-2.5 tw-ms-2 tw-bg-gray-200 tw-rounded-full dark:tw-bg-gray-700 tw-w-9/12"></div>
                            </div>
                            <div className="tw-flex tw-items-center tw-w-full">
                                <div className="tw-h-2.5 tw-bg-gray-300 tw-rounded-full dark:tw-bg-gray-600 tw-w-5/12"></div>
                                <div className="tw-h-2.5 tw-ms-2 tw-bg-gray-200 tw-rounded-full dark:tw-bg-gray-700 tw-w-2/12"></div>
                                <div className="tw-h-2.5 tw-ms-2 tw-bg-gray-300 tw-rounded-full dark:tw-bg-gray-600 tw-w-5/12"></div>
                            </div>
                            <span className="sr-only">Loading...</span>
                        </div>

                        <div className="tw-animate-pulse tw-h-96 tw-w-96 tw-bg-gray-200" />

                    </div>
                </div>

            </div>
        </div>
    )

}

var settings = {
    fade: true,
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay:true,
    autoplaySpeed:5000,
    loop:false
};

function Slider ( props:TSlider<PropsFromRedux> ) {
    console.log("Slider.rendered: ", props);

    useEffect(
        () => {
            if ( !Lodash.isEmpty(props.layoutName) && props.layoutName ) {
                props._listSliderItems(props.layoutName, props.webApplicationLanguageCode);
            }
        }, []
    )

    useEffect (
        () => {

            if ( !isEmpty(props.sliderItemListPayload) && !props.sliderItemListIsLoading ) {
                props._hideLoader();
            }
        
        }, [ props.sliderItemListIsLoading ]
    )

    if ( props.sliderItemListIsLoading ) {
        
        return <Loader />;

    }
    else if ( isEmpty(props.sliderItemListError) ) {

        // image preload connection
        const listOfSliderImages = props.sliderItemListPayload?.map((item:IClientSliderItemList, index:number) => {
            const filePath = ApplicationDocumentSelector<IApplicationDocument|null>({
                resources: item.resources,
                resourceType: EResourceType.PATTERN,
                type: "find"
            })?.filePath;
            return <link rel="preload" as="image" href={`${CoreDefault.cdn}/${filePath}`} />;
        });

        return (
            <>
                <Helmet>{listOfSliderImages}</Helmet>

                <div className="container-fluid">
                    <div id="ARMADOR_Banner_01" className="row">
                        <SlickSlider {...settings}>
                            
                            {
                                props.sliderItemListPayload?.map((item:IClientSliderItemList, index:number) => {
                                    const layout = item?.layouts?.find((item:any) => item.layoutType === ELayoutType.SLIDER.id);
                                    const Layout:any = layout ? SliderLayouts[layout.layoutName] : SliderLayouts["SLDR_001"];

                                    return ( 
                                        <Layout
                                            index={index}
                                            payload={item}
                                            siteSettingPayload={props.siteSettingsDetailsPayload}
                                        />
                                    );

                                })
                            }

                        </SlickSlider>
                    </div>
                </div>
            </>
            
        )

    }

    return null;

}

Slider.defaultProps = {

    layoutName: "SLDRGRP_001"

}

const Component = React.memo(Slider, ( prevProps:TSlider<PropsFromRedux>, nextProps:TSlider<PropsFromRedux> ) => {
    console.log("Slider.memo", { prevProps, nextProps });
    return !(
        prevProps.webApplicationLanguageISOCode !== nextProps.webApplicationLanguageISOCode
        || prevProps.sliderItemListPayload !== nextProps.sliderItemListPayload
    );
});

export default connector(Component);
