import { useEffect, useState, CSSProperties } from "react";
import { isEmpty } from "lodash";
import CoreDefaults from 'core/Defaults';
// utilities
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
import ApplicationDocumentSelector from "cms/utilities/ApplicationDocumentSelector";
// enums
import { EResourceType } from 'cms/enums/EResourceType';
import ESizeType from "cms/enums/ESizeType";
import EMimeType from "cms/enums/EMimeType";
// interfaces
import IResource from 'cms/interfaces/IResource';
import IApplicationDocument from "cms/interfaces/IApplicationDocument";
// image
import defaultImage from "assets/img/default-image.webp";
import TMimeType from "cms/types/TMimeType";


type TRMDRImage = {

    id?:string;
    src?: string;

    resources?: IResource[];
    
    resourceType?: EResourceType;
    sizeType?: ESizeType;

    alt?: string|null;
    title?: string|null;

    style?: CSSProperties;
    className?: string; 

    width?: number|string;
    height?: number|string;

    disableLazyLoading?:boolean;

}

type TImagePayload = {
    
    imageSource:any;
    alt:string;
    title:string;
    contentType:TMimeType;
    width:number;
    height:number;
    isLoad:boolean;
    _isError:boolean;

}


function RMDRImage ( props:Readonly<TRMDRImage> ) {

    const [ payload, setPayload ] = useState<TImagePayload|null>(null);

    useEffect(
        () => {
            
            const applicationDocument = ApplicationDocumentSelector<IApplicationDocument|null>({ 
                resources: props.resources,
                resourceType: props.resourceType,
                sizeType: props.sizeType,
                type: "find"
            });

            if ( !isEmpty(applicationDocument) && applicationDocument ) {

                const imageURL = `${CoreDefaults.cdn}/${applicationDocument.filePath}`;
                if ( applicationDocument.contentType === EMimeType.SVG.name ) {

                    let _imageSource:any = null;
                    let _isError:boolean = false;

                    fetch(
                        imageURL, 
                        { method: "GET", mode: 'cors' }
                    )
                    .then(res => res.text())
                    .then((value:any) => _imageSource = value)
                    .catch(() => _isError = true)
                    .then(() => setPayload({ imageSource: _imageSource, alt:props.alt||applicationDocument.name, title:props.title||applicationDocument.name, contentType: applicationDocument.contentType, width: applicationDocument.width, height: applicationDocument.height, isLoad: true, _isError: _isError }))
                    
                }
                else {

                    setPayload({ imageSource: imageURL, alt:props.alt||applicationDocument.name, title:props.title||applicationDocument.name, contentType: applicationDocument.contentType, width: applicationDocument.width, height: applicationDocument.height, isLoad: true, _isError: false});

                }

            }

        }, [ props.resources ]
    );

    function replaceImage (error:any) {
    
        error.target.path = defaultImage;
    
    }

    if ( payload?.imageSource && !isEmpty(payload?.imageSource) ) {
        if ( payload.contentType === EMimeType.SVG.name ) {
            if ( payload.imageSource && payload.isLoad && !payload._isError ) {
                return <div 
                    id={props.id} 
                    
                    title={payload.title||""} 
                    
                    style={props.style} 
                    className={props.className} 

                    dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(payload.imageSource) }} 
                />;
            }
        }
        else {
            return <img 
                id={props.id} 
                src={payload.imageSource}

                alt={payload.alt} 
                title={payload.title} 

                style={props.style} 
                className={props.className} 

                width={payload.width||"100%"} 
                height={payload.height||"100%"}

                onError={replaceImage} 
                loading={props.disableLazyLoading?"eager":"lazy"} 
            />
        }
    }
    else if ( props?.src && !isEmpty(props?.src) ) {

        return <img 
            id={props.id} 
            src={props.src} 

            alt={props.alt||undefined}
            title={props.title||""} 
            
            style={props.style} 
            className={props.className} 

            width={props.width||"100%"} 
            height={props.height||"100%"} 

            onError={replaceImage} 
            loading={props.disableLazyLoading?"eager":"lazy"} 
        />

    }

    return null;

}

export default RMDRImage;
export type {
    TRMDRImage
};
