import { isEmpty } from 'lodash';
import CoreDefault from "core/Defaults";
// enums
import { EResourceType } from "cms/enums/EResourceType";
import ESizeType from "cms/enums/ESizeType";
import { EView } from 'cms/enums/EView';
import { EViewType } from 'cms/enums/EViewType';
// interfaces
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import ApplicationDocumentSelector from './ApplicationDocumentSelector';
import LanguageVersionSelector from './LanguageVersionSelector';
import ILanguageVersionDetail from 'cms/interfaces/ILanguageVersionDetail';
import IApplicationDocument from 'cms/interfaces/IApplicationDocument';


function PutManifestFileDynamic( document: any, siteSettingPayload:IClientSiteSettingDetail ) {

    let dynamicManifest = {
        name: "",
        short_name: "",
        start_url: document.location.origin,
        display: "standalone",
        background_color: "#ffffff",
        theme_color: "#ffffff",
        description: "",
        icons: []
    }

    // icon
    if ( siteSettingPayload.resources && !isEmpty(siteSettingPayload.resources) ) {

        let logo_x01 = ApplicationDocumentSelector<IApplicationDocument>({
            resources: siteSettingPayload.resources,
            resourceType: EResourceType.LOGO_ONLY,
            sizeType: ESizeType.X01,
            type: "find",
            disableDeriveds: true
        });
        if ( logo_x01 ) {
            dynamicManifest["icons"].push({
                "src": `${CoreDefault.cdn}/${logo_x01.filePath}`,
                "type": logo_x01.contentType,
                "sizes": `${logo_x01.width}x${logo_x01.height}`
            } as never);
        }

        let logo_x02 = ApplicationDocumentSelector<IApplicationDocument>({
            resources: siteSettingPayload.resources,
            resourceType: EResourceType.LOGO_ONLY,
            sizeType: ESizeType.X02,
            type: "find",
            disableDeriveds: true
        });
        if ( logo_x02 ) {
            dynamicManifest["icons"].push({
                "src": `${CoreDefault.cdn}/${logo_x02.filePath}`,
                "type": logo_x01.contentType,
                "sizes": `${logo_x01.width}x${logo_x01.height}`
            } as never);
        }

        let logo_x03 = ApplicationDocumentSelector<IApplicationDocument>({
            resources: siteSettingPayload.resources,
            resourceType: EResourceType.LOGO_ONLY,
            sizeType: ESizeType.X03,
            type: "find",
            disableDeriveds: true
        });
        if ( logo_x03 ) {
            dynamicManifest["icons"].push({
                "src": `${CoreDefault.cdn}/${logo_x03.filePath}`,
                "type": logo_x01.contentType,
                "sizes": `${logo_x01.width}x${logo_x01.height}`
            } as never);
        }

        let logo_x06 = ApplicationDocumentSelector<IApplicationDocument>({
            resources: siteSettingPayload.resources,
            resourceType: EResourceType.LOGO_ONLY,
            sizeType: ESizeType.X06,
            type: "find",
            disableDeriveds: true
        });
        if ( logo_x06 ) {
            dynamicManifest["icons"].push({
                "src": `${CoreDefault.cdn}/${logo_x06.filePath}`,
                "type": logo_x01.contentType,
                "sizes": `${logo_x01.width}x${logo_x01.height}`
            } as never);
        }

    }

    // language version
    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: siteSettingPayload.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION }
        ],
        type: "find"
    });

    const description = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: siteSettingPayload.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "metaDescription" }
        ],
        type: "find"
    });

    dynamicManifest["name"] = languageVersion?.name||"";
    dynamicManifest["short_name"] = languageVersion?.name||"";
    dynamicManifest["description"] = description?.value||"";
    
    // 
    const stringManifest = JSON.stringify(dynamicManifest);
    const blobManifest = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blobManifest);
    document?.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);

}

export default PutManifestFileDynamic;
