import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { isEmpty } from "lodash";
// components
import MainPageLayout from "components/MainPageLayouts";
// actions
import CoreAction from "core/actions/common/CoreAction";
import ClientMainPageItemsServiceAction from "cms/actions/components/MainPageItem/ClientMainPageItemsServiceAction";
// styles
import 'react-tabs/style/react-tabs.css';
// interfaces
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
import IClientMainPageItemList from "cms/interfaces/IClientMainPageItemList";
// enums
import ELayoutType from "cms/enums/ELayoutType";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import TValidations from "cms/types/TValidations";
import { Dispatch } from "@reduxjs/toolkit";
import { EContentStatus } from "cms/enums/EContentStatus";


function mapStateToProps ( state: any ) {
    console.log("MainPageItem.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const mainPageItemsListPayload:IClientMainPageItemList[]|null   = state.MainPageItemStore.ClientMainPageItemsStore.list?.payload;
    const mainPageItemsListError:TValidations[]|null                = state.MainPageItemStore.ClientMainPageItemsStore.list?.error;
    const mainPageItemsListIsLoading:boolean                        = state.MainPageItemStore.ClientMainPageItemsStore.list?.isLoading;

    const siteSettingsDetailsPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        mainPageItemsListPayload,
        mainPageItemsListError,
        mainPageItemsListIsLoading,

        siteSettingsDetailsPayload,
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {

    const coreAction = new CoreAction(dispatch);
    const clientMainPageItemsServiceAction = new ClientMainPageItemsServiceAction(dispatch);

    return {

        // list
        _listMainPageItems: ( mapLayoutName:string, webApplicationLanguageCode:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`status eq ${EContentStatus.ACTIVE}`, "and", `mapLayoutName eq '${mapLayoutName}'`],
                orderBy: ["order asc"],
                top: 1000
            };

            clientMainPageItemsServiceAction.list({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
        },

        _hideLoader: () => { 
            coreAction.hideLoader(); 
        }

    }

}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TMainPageItem<T={}> = {

    mapLayoutName?:string;

} & T;

/* component */
function Loader () {

    return (
        <></>
    )

}

function MainPageItem ( props:TMainPageItem<PropsFromRedux> ) {
    console.log("MainPageItem.rendered: ", props);

    useEffect(
        () => {
            if ( !isEmpty(props.mapLayoutName) && props.mapLayoutName ) 
                props._listMainPageItems( props.mapLayoutName, props.webApplicationLanguageCode );
        }, []
    )

    useEffect(
        () => {

            if ( !isEmpty(props.mainPageItemsListPayload) && !props.mainPageItemsListIsLoading ) {
                props._hideLoader();
            }

        }, [ props.mainPageItemsListIsLoading ]
    )

    if ( props.mainPageItemsListIsLoading ) {

        return <Loader />

    }
    else if ( isEmpty(props.mainPageItemsListError) ) {

        const mainPageParentLayouts = props.mainPageItemsListPayload?.filter((item:IClientMainPageItemList) => item.parentId === null );

        return (
            <>
                {
                    mainPageParentLayouts?.map((item:IClientMainPageItemList) => { 
                        const layout = item.layouts.find((item) => item.layoutType === ELayoutType.MAIN_PAGE.id );

                        if ( layout ) {
                            const Layout = MainPageLayout[layout.layoutName];
                            const childs = props.mainPageItemsListPayload?.filter((cItem:IClientMainPageItemList) => cItem.parentId === item.id);
                            
                            if ( Layout ) {
                                return <Layout parentPayload={item} childsPayload={childs} siteSettingPayload={props.siteSettingsDetailsPayload} />
                            }
                        }

                        return null;
                    })
                }
            </>
        )

    }
    
    return null;

}

MainPageItem.defaultProps = {

    mapLayoutName: "MNPGGRP_001"

}

const Component = React.memo(MainPageItem, ( prevProps:TMainPageItem<PropsFromRedux>, nextProps:TMainPageItem<PropsFromRedux> ) => {
    console.log("MainPageItem.memo", { prevProps, nextProps });
    return !(prevProps.mainPageItemsListIsLoading !== nextProps.mainPageItemsListIsLoading );
});

export default connector(Component);
