// action
import OdataActionBase from "core/actions/OdataActionBase";
// service
import ContentMetricsService from "core/services/metrics/ContentMetricsService";


class ContentMetricsServiceAction extends OdataActionBase {

    constructor( dispatch: any, screenCode:string ) {

        super(dispatch, ContentMetricsService, screenCode);
    
    }

}

export default ContentMetricsServiceAction;
