import React from "react";
// types
import { TClientMenuItemList } from "../type";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
// components
import RMDRNavLink from "components/RMDRNavLink";
import { EContentType } from "cms/enums/EContentType";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import { connect, ConnectedProps } from "react-redux";
import ContentMetricsServiceAction from "cms/actions/components/Header/ContentMetricsServiceAction";
import { EContentMetricsType } from "core/enums/EContentMetricsType";
import IClientLanguage from "core/interfaces/IClientLanguage";
import { Dispatch } from "@reduxjs/toolkit";


function mapStateToProps ( state: any ) {
    console.log("NormalMenu.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

    }

}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("NormalMenu.mapDispatchToProps");

    const contentMetricsServiceAction = new ContentMetricsServiceAction(dispatch);

    return {
        
        _viewEvent: ( contentId:string, webApplicationLanguageCode:string ) => {
            contentMetricsServiceAction.post({ 
                servicePayload: { 
                    body: {
                        contentId: contentId,
                        metricType: EContentMetricsType.VIEV
                    }, 
                    language: webApplicationLanguageCode 
                } 
            });
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>
type TNormalMenu<T> = {

    navigation: []

} & T;

function NormalMenu ( props:Readonly<TNormalMenu<PropsFromRedux>> ) {

    return (
        <nav id="menu" role="navigation" className="menu">
            <ul>
                {
                    props.navigation?.map(
                        (item:TClientMenuItemList) => {

                            const languageVersion = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                languageVersions: item.languageVersions,
                                filters: [
                                    { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                ],
                                type: 'find'
                            });
                    
                            const redirectUrl = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                languageVersions: item.languageVersions,
                                filters: [
                                    { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
                                ],
                                type: 'find'
                            });

                            if ( languageVersion ) {
                                let className = item.items ? "menu-hasdropdown" : "";

                                return (
                                    <li key={item.id} className={className}>
                                        <RMDRNavLink
                                            title={languageVersion?.name}
                                            text={languageVersion?.name}

                                            hasNavigationDestination={item.hasDestinationNavigation}
                                            isShowAlways={true}

                                            navigationURL={languageVersion?.contentRouteNavigationURL}

                                            redirectType={item.redirectType}
                                            redirectUrl={redirectUrl?.value}

                                            activeClassName={"font-semibold text-neutral-700 dark:!text-neutral-200"}
                                            onClick={(event:any) => { props._viewEvent(item.id, props.webApplicationLanguageCode) }}
                                            variant="div"
                                        />
                                        {
                                            item.items && <ul className="menu-dropdown">
                                                {
                                                    item.items?.map((childMenuItem1:TClientMenuItemList) => {

                                                        const languageVersionL1 = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                                            languageVersions: childMenuItem1.languageVersions,
                                                            filters: [
                                                                { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                                            ],
                                                            type: 'find'
                                                        });
                                                
                                                        const redirectUrlL1 = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                                            languageVersions: childMenuItem1.languageVersions,
                                                            filters: [
                                                                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
                                                            ],
                                                            type: 'find'
                                                        });

                                                        if ( languageVersionL1 ) {
                                                            let classNameC1 = childMenuItem1.items ? "menu-hasdropdown menu-hasflyout" : "";

                                                            return (
                                                                <li key={childMenuItem1.id} className={classNameC1}>
                                                                    <RMDRNavLink
                                                                        title={languageVersionL1?.name}
                                                                        text={languageVersionL1?.name}

                                                                        hasNavigationDestination={childMenuItem1?.hasDestinationNavigation}
                                                                        isShowAlways={true}

                                                                        navigationURL={languageVersionL1?.contentRouteNavigationURL}

                                                                        redirectType={childMenuItem1.redirectType}
                                                                        redirectUrl={redirectUrlL1?.value}

                                                                        activeClassName={"font-semibold text-neutral-700 dark:!text-neutral-200"}
                                                                        onClick={(event:any) => { props._viewEvent(childMenuItem1.id, props.webApplicationLanguageCode) }}
                                                                        variant="div"
                                                                    />
                                                                    {
                                                                        childMenuItem1.items && <ul className="menu-dropdown">
                                                                            {
                                                                                childMenuItem1.items.map((childMenuItem2:TClientMenuItemList) => {

                                                                                    const languageVersionL2 = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                                                                        languageVersions: childMenuItem2.languageVersions,
                                                                                        filters: [
                                                                                            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                                                                        ],
                                                                                        type: 'find'
                                                                                    });
                                                                            
                                                                                    const redirectUrlL2 = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                                                                        languageVersions: childMenuItem2.languageVersions,
                                                                                        filters: [
                                                                                            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
                                                                                        ],
                                                                                        type: 'find'
                                                                                    });

                                                                                    if ( languageVersionL2 ) {                                                                                        
                                                                                        return <li key={childMenuItem2.id}>
                                                                                            <RMDRNavLink
                                                                                                title={languageVersionL2?.name}
                                                                                                text={languageVersionL2?.name}

                                                                                                hasNavigationDestination={childMenuItem2?.hasDestinationNavigation}
                                                                                                isShowAlways={true}
                        
                                                                                                navigationURL={languageVersionL2?.contentRouteNavigationURL}
                        
                                                                                                redirectType={childMenuItem2.redirectType}
                                                                                                redirectUrl={redirectUrlL2?.value}
                        
                                                                                                activeClassName={"font-semibold text-neutral-700 dark:!text-neutral-200"}
                                                                                                onClick={(event:any) => { props._viewEvent(childMenuItem2.id, props.webApplicationLanguageCode) }}
                                                                                                variant="div"
                                                                                            />
                                                                                        </li>
                                                                                    }
                                                                                    return null;
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    }
                                                                </li>
                                                            );
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </ul>
                                        }
                                    </li>
                                )
                            }

                            return null;
                        }
                    )
                }
            </ul>
        </nav>
    )

}

NormalMenu.defaultProps = {

    navigation: [],

};

const Component = React.memo(NormalMenu, (prevProps, nextProps) => {
    console.log("NormalMenu.React.memo", {prevProps, nextProps});
    return !( prevProps.navigation !== nextProps.navigation );
});

export default connector(Component);
export type {
    TNormalMenu
};
