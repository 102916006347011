const EContentMetricsTypeDataSource = {

    LIKE        : { id: 1, name: "Like",        description: "Like" },
    COPY_PATH   : { id: 2, name: "CopyPath",    description: "CopyPath" },
    VIEV        : { id: 3, name: "View",        description: "View" },
    DOWNLOAD    : { id: 4, name: "Download",    description: "Download" }

}


enum EContentMetricsType {

    LIKE        = 1,
    COPY_PATH   = 2,
    VIEV        = 3,
    DOWNLOAD    = 4

}

export default EContentMetricsTypeDataSource;
export {

    EContentMetricsType

}
