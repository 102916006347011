import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import CoreDefault from "core/Defaults";
// components
import RMDRTextContent from "components/RMDRTextContent";
import RMDRTextBox from "components/RMDRTextBox";
import RMDRTextArea from "components/RMDRTextArea";
import RMDRButton from "components/RMDRButton";
import RMDRImage from "components/RMDRImage";
import { MdContactPhone, MdEmail } from "react-icons/md";
// actions
import CoreAction from "core/actions/common/CoreAction";
import MessagePublisherAction from "core/actions/common/MessagePublisherAction";
import ContactUsServiceAction from "cms/actions/components/InnerLayouts/INNR_003/ContactUsServiceAction";
// enums
import { EView } from "cms/enums/EView";
import { EContentType } from "cms/enums/EContentType";
import { EViewType } from "cms/enums/EViewType";
import { EResourceType } from "cms/enums/EResourceType";
// utilities
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
// interfaces
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IBase from "cms/interfaces/IBase";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
// types
import { TINNRLayout } from "components/InnerLayouts/type";
import TValidations from "cms/types/TValidations";


function mapStateToProps ( state: any ) {
    console.log("INNR004.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const contactUsPostPayload:IBase                = state.INNR003Store.ContactUsStore.post?.payload;
    const contactUsPostError:TValidations[]|null    = state.INNR003Store.ContactUsStore.post?.error?.value;
    const contactUsPostIsLoading:boolean            = state.INNR003Store.ContactUsStore.post?.isLoading;

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        contactUsPostPayload,
        contactUsPostError,
        contactUsPostIsLoading,
        
        siteSettingDetailPayload,

    }
}

function mapDispatchToProps(dispatch: Dispatch) {

    const coreAction = new CoreAction(dispatch);
    const contactUsServiceAction = new ContactUsServiceAction(dispatch);
    const messagePublisherAction = new MessagePublisherAction(dispatch);

    return {
        // list
        // post
        _post: ( payload:any, webApplicationLanguageCode:string ) => {
            contactUsServiceAction.post({ servicePayload: { body: payload, language: webApplicationLanguageCode } })
        },
        
        _showMessage: ( message:string ) => { 
            messagePublisherAction.showSuccessMessage(message);
        },

        // details
        // others
        _showLoader: () => { 
            coreAction.showLoader() 
        },

        _hideLoader: () => { 
            coreAction.hideLoader() 
        },

        _clean: () => {
            contactUsServiceAction.postClean();
        }
    }

}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

function INNR004(props: TINNRLayout<PropsFromRedux>) {
    console.log("INNR004.rendered: ", props);

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.contentDetailPayload?.languageVersions,
        filters: [
            { view: EView.INNER, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const phoneNumber = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.contentDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT, name: "phoneNumber" }
        ],
        type: "find"
    });

    const emailAddress = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.contentDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT, name: "email" }
        ],
        type: "find"
    });

    const title = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.contentDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT, name: "title" }
        ],
        type: "find"
    });

    const [ verboseResources, setVerboseResources ] = useState<any>({});
    const [ disabled, setDisabled ] = useState(false);
    const [ vCard, setVCard ] = useState<string|null>(null);


    const contactUsNameSurname = useRef<any>(null);
    const contactUsEmail = useRef<any>(null);
    const contactUsMessage = useRef<any>(null);
    const contactUsTitle = useRef<any>(null);

    useEffect(
        () => {
            // string Values
            const fullNameString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "fullNameString" }], type: 'find' });
            const emailAddressString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "emailAddressString" }], type: 'find' });
            const writeAMessageString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "writeAMessageString" }], type: 'find' });
            const subjectString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "subjectString" }], type: 'find' });
            const submitString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "submitString" }], type: 'find' });

            const sendMessageString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "sendMessageString" }], type: 'find' });
            const messageSendingString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "messageSendingString" }], type: 'find' });
            const messageHasBeenSentString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "messageHasBeenSentString" }], type: 'find' });

            setVerboseResources({
                fullNameString, emailAddressString, writeAMessageString, subjectString, submitString,
                languageVersion, phoneNumber,
                sendMessageString, messageSendingString, messageHasBeenSentString
            });
            
            setTimeout(() => { props._hideLoader(); }, 100);

            // fetchVCard();

            return function cleanup() {

                props._clean();
                props._showLoader();

            }

        }, []
    )

    const vcardDocumentURL = props.contentDetailPayload.resources?.find((item) => item.resourceTypeId === EResourceType.DATA)?.applicationDocument;


    

    useEffect(
        () => {
            if ( !isEmpty(props.contactUsPostPayload?.id) ) {
                contactUsNameSurname.current.value = "";
                contactUsEmail.current.value = "";
                contactUsMessage.current.value = "";
                contactUsTitle.current.value = "";

                props._clean();
                setDisabled(false);
                props._showMessage(verboseResources.messageHasBeenSentString?.value||"");
                
            }
        }, [ props.contactUsPostPayload ]
    )

    useEffect(
        () => {
            if ( !isEmpty(props.contactUsPostError) ) {
                setDisabled(false);
            }
        }, [ props.contactUsPostPayload ]
    )
    
    function onSubmit(event:React.SyntheticEvent) { 
        event.preventDefault();

        setDisabled(true);

        const target = event.target as typeof event.target & {
            nameSurname: { value: string },
            title: { value: string },
            email: { value: string },
            message: { value: string },
        };

        const payload:any = {
            nameSurname: target.nameSurname.value,
            email: target.email.value,
            message: target.message.value,
            title: target.title.value,
        }
        props._post(payload, props.webApplicationLanguageCode);
    }

    // async function fetchVCard() {
    //     console.log("fetchVCard")

    //     const vcardDocumentURL = props.contentDetailPayload.resources?.find((item) => item.resourceTypeId === EResourceType.DATA)?.applicationDocument;

    //     console.log("fetchVCard: ", vcardDocumentURL);

    //     if ( vcardDocumentURL && !isEmpty(vcardDocumentURL) ) {
    //         const vCardBlob = await fetch(`${CoreDefault.cdn}/${vcardDocumentURL?.filePath}`).then(r => r.text());
    //         console.log("fetchVCard: ", vcardDocumentURL?.filePath);
    //         setVCard(vCardBlob);
    //     }
        
    // }

    // function downloadVCF(event:React.SyntheticEvent) { 
    //     if ( vCard ) {
    //         const element = document.createElement("a");
    //         const file = new Blob([vCard], { type: "text/x-vcard;charset=utf-8" });
    //         element.href = URL.createObjectURL(file);
    //         element.download = `${languageVersion?.name}.vcf`;
    //         document.body.appendChild(element);
    //         element.click();
    //     }
    // }

    return (
        <section className="ttm-row team-details-section clearfix">
            <div className="container-xl">
                <div className="row">

                    <div className="col-lg-4 tw-">
                        <div className="ttm-featured-wrapper">
                            <div className="featured-thumbnail text-center">
                                <a href={`${CoreDefault.cdn}/${vcardDocumentURL?.filePath}`} >
                                    <RMDRImage
                                        resourceType={EResourceType.INNER_IMAGE}
                                        resources={props.contentDetailPayload?.resources} 
                                        alt={languageVersion?.name}
                                        title={languageVersion?.name}
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="">
                            <a href={`${CoreDefault.cdn}/${vcardDocumentURL?.filePath}`} className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor tw-w-full !tw-text-base tw-text-center">
                                <i className="fa fa-user" />
                                Add Contact
                            </a>

                             {/* <button type="submit" id="submit" className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor tw-w-full !tw-text-base" onClick={downloadVCF}>
                                 <i className="fa fa-user" />
                                 Add Contact
                             </button> */}
                        </div>
                    </div>

                    <div className="col-lg-8">
                        <div className="ttm-team-member-content shadow-box res-991-mt-30">
                            <div className="ttm-team-member-single-list">
                                <h2 className="ttm-team-member-single-title">{languageVersion?.name}</h2>
                                <span className="ttm-team-member-single-position">{title?.value}</span>
                                <RMDRTextContent parentLanguageVersion={languageVersion} />
                                
                                <div className="separator">
                                    <div className="sep-line mt-4 mb-4" />
                                </div>

                                <div className="ttm-team-data">
                                    <div className="ttm-team-details-wrapper">
                                        <ul className="ttm-team-details-list clearfix">
                                            <li>
                                                <div className="ttm-team-list-title"><MdContactPhone /></div>
                                                <div className="ttm-team-list-value"><a className="tw-no-underline" href={`tel:${phoneNumber?.value||""}`}>{phoneNumber?.value}</a></div>
                                            </li>
                                            <li>
                                                <div className="ttm-team-list-title"><MdEmail /></div>
                                                <div className="ttm-team-list-value"><a className="tw-no-underline" href={`mailto:${emailAddress?.value}`}>{emailAddress?.value}</a></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="separator">
                                    <div className="sep-line mt-3 mb-4" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
                <div className="row tw-mt-10">
                    <div className="col-12">
                        <div className="member-contact-form border res-991-mt-30">

                            <form id="ttm-quote-form" className="row ttm-quote-form clearfix" onSubmit={onSubmit} >
                               
                                <div className="col-sm-12 col-md-6">
                                    <div className="input-group mb-3 tw-flex-col">
                                        <RMDRTextBox rmdrRef={contactUsNameSurname} name="nameSurname" className="form-control ttm-bgcolor-grey !tw-w-full" placeholder={verboseResources.fullNameString?.value} disabled={disabled} errors={props.contactUsPostError} />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <div className="input-group mb-3 tw-flex-col">
                                        <RMDRTextBox rmdrRef={contactUsEmail} name="email" className="form-control ttm-bgcolor-grey !tw-w-full" placeholder={verboseResources.emailAddressString?.value} disabled={disabled} errors={props.contactUsPostError} />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-12">
                                    <div className="input-group mb-3 tw-flex-col">
                                        <RMDRTextBox rmdrRef={contactUsTitle} name="title" className="form-control ttm-bgcolor-grey !tw-w-full" placeholder={verboseResources.subjectString?.value} disabled={disabled} errors={props.contactUsPostError} />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-12">
                                    <div className="input-group mb-3 tw-flex-col">
                                        <RMDRTextArea rmdrRef={contactUsMessage} name="message" className="form-control ttm-bgcolor-grey !tw-w-full" placeholder={verboseResources.writeAMessageString?.value} disabled={disabled} rows={5} errors={props.contactUsPostError} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="text-left">
                                        <RMDRButton
                                            type="submit"
                                            value={verboseResources.sendMessageString?.value}
                                            disabledValue={verboseResources.messageSendingString?.value}
                                            disabled={disabled}
                                            className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                                        />
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

const Component = React.memo(INNR004, (prevProps: TINNRLayout<PropsFromRedux>, nextProps: TINNRLayout<PropsFromRedux>) => {
    console.log("INNR004.memo", { prevProps, nextProps });
    return !( prevProps.contactUsPostIsLoading !== nextProps.contactUsPostIsLoading );
});

export default connector(Component);
