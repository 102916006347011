import React, { Suspense } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// components
import SEO from "components/SEO";
// actions
import CoreAction from "core/actions/common/CoreAction";
// interfaces
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
// styles
import 'react-tabs/style/react-tabs.css';
// lazy element
const SliderLazy = React.lazy(() => import("components/Slider"));
const MainPageItemLazy = React.lazy(() => import("components/MainPageItem"));


function mapStateToProps ( state: any ) {
    console.log("Home.mapStateToProps: ", state);

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;
    
    return {
        
        siteSettingDetailPayload

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    const coreAction = new CoreAction(dispatch);

    return {

        // others
        _hideLoader: () => {
            coreAction.hideLoader();
        },

        _showLoader: () => {
            coreAction.showLoader();
        },

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type THome<T> = {

} & T;


/* component */
function Loader () {

    return (
        <></>
    )

}

function Home ( props:THome<PropsFromRedux> ) {
    console.log("Home.rendered: ", props);
    
    return (
        <>
            <SEO siteSettingPayload={props.siteSettingDetailPayload} type="home-page" />

            <Suspense fallback={<div></div>}>
                <SliderLazy />
            </Suspense>
            
            <div className="site-main">
                <Suspense fallback={<div></div>}>
                    <MainPageItemLazy />
                </Suspense>
            </div>
        </>
    );
}

const Component = React.memo(Home, ( prevProps:THome<PropsFromRedux>, nextProps:THome<PropsFromRedux> ) => {
    console.log("Home.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
